<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center mb-4">
        <div class="flex-grow-1">
          <h5 class="card-title mb-0">Ritwayat Transaksi</h5>
        </div>
      </div>
      <DataTable
        :url="dataTableUrl"
        :columns="columnHeaders"
        :actions="actions"
        :reload="reloadDataTable"
      />
    </div>
  </div>
</template>

<script>
import { endpoint } from "../../../host";
import DataTable from "../../../components/datatable/DataTable.vue";
import moment from "moment";

export default {
  components: {
    DataTable,
  },
  props: {
    id: { type: Number, required: true },
  },

  created() {},

  data() {
    return {
      reloadDataTable: false,
      dataTableUrl: endpoint.transaction + `?investor_id=${this.id}`,
      actions: [
        // {
        //   icon: "bx bx-edit",
        //   color: "btn-warning",
        //   tooltip: "edit",
        //   onclick: (data) => {
        //   },
        // },
      ],
      columnHeaders: [
        {
          text: "Tanggal",
          render: (data) => {
            return moment(data.created_at).format("DD MMMM YYYY");
          },
        },
        {
          text: "Jenis",
          name: "type",
        },
        {
          text: "Jumlah",
          render: (data) => {
              return new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(data['amount']);
          },
        },
        {
          text: "Keterangan",
          name: "description",
        },
        {
          text: "Status",
          render: (data) => {
              if(data['status'] == 'Y')
              {
                  return 'Berhasil';
              }
              return 'Gagal';
          },
        },
      ],
    };
  },
};
</script>

<style>
</style>