<template>
  <div class="card" v-if="list.share != null">
    <div class="card-body">
      <div class="d-flex align-items-center mb-4">
        <div class="flex-grow-1">
          <h5 class="card-title mb-0">Saham</h5>
        </div>
      </div>
      <div>
        <div
          class="d-flex align-items-center py-3"
          v-for="item in list.share"
          :key="item"
        >
          <div class="avatar-sm me-2">
            <div
              class="avatar-title bg-soft-primary text-primary rounded fs-20"
            >
              <i class="ri-file-zip-fill"></i>
            </div>
          </div>
          <div class="flex-grow-1">
            <div>
              <h5 class="fs-14 mb-1">
                {{ item["slot"] }} Slot {{ item["share_period"]["name"] }}
              </h5>
              <p class="fs-13 text-muted mb-0">
                {{ item["share_number"] }}
              </p>
            </div>
          </div>
          <div class="flex-shrink-0 ms-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-success"
              @click="downloadCertificate(item['share_number'])"
            >
              <i class="ri-download-line align-middle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoint } from "../../../host";
import errorService from "../../../services/error-service";
import httpService from "../../../services/http-service";
export default {
  name: "ShareTabComponent",
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      list: {
        share: null,
      },
    };
  },
  created() {
    this.getInvestor();
  },
  methods: {
    async getInvestor() {
      try {
        let res = await httpService.get(endpoint.share, {
          headers: httpService.authHeader(),
          params: {
            investor_id: this.id,
          },
        });
        this.list.share = res.data.data;
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    downloadCertificate(share_number) {
      let url = endpoint["certificate.download"] + `/${share_number}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
</style>