<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
          <img
            src="/assets/images/profile-bg.jpg"
            alt=""
            class="profile-wid-img"
          />
        </div>
      </div>
      <investor-profile-shimmer :investor="investor"></investor-profile-shimmer>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="d-flex">
              <!-- Nav tabs -->
              <ul
                class="
                  nav nav-pills
                  animation-nav
                  profile-nav
                  gap-2 gap-lg-3
                  flex-grow-1
                "
                role="tablist"
              >
                <li class="nav-item" @click="changeActivedTab('biodata')">
                  <a
                    class="nav-link fs-14 active"
                    data-bs-toggle="tab"
                    href="#overview-tab"
                    role="tab"
                    aria-selected="true"
                  >
                    <i class="ri-airplay-fill d-inline-block d-md-none"></i>
                    <span class="d-none d-md-inline-block">Biodata</span>
                  </a>
                </li>
                <li class="nav-item" @click="changeActivedTab('share')">
                  <a
                    class="nav-link fs-14"
                    data-bs-toggle="tab"
                    href="#activities"
                    role="tab"
                    aria-selected="false"
                  >
                    <i class="ri-list-unordered d-inline-block d-md-none"></i>
                    <span class="d-none d-md-inline-block">Saham</span>
                  </a>
                </li>
                <li class="nav-item" @click="changeActivedTab('transaction')">
                  <a
                    class="nav-link fs-14"
                    data-bs-toggle="tab"
                    href="#projects"
                    role="tab"
                    aria-selected="false"
                  >
                    <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                    <span class="d-none d-md-inline-block"
                      >Riwayat Transaksi</span
                    >
                  </a>
                </li>
              </ul>
              <div class="flex-shrink-0">
                <router-link
                  :to="{ name: 'investor' }"
                  type="button"
                  class="btn btn-warning waves-effect waves-light"
                  ><i
                    class="bx bx-chevrons-left font-size-16 align-middle me-2"
                  ></i>
                  Kembali
                </router-link>
              </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content pt-4 text-muted">
              <div
                class="tab-pane"
                :class="{
                  active: actived_tab == 'biodata',
                  fade: actived_tab != 'biodata',
                }"
                role="tabpanel"
              >
                <biodata-tab-component
                  :investor="investor"
                ></biodata-tab-component>
              </div>
              <div
                class="tab-pane"
                :class="{
                  active: actived_tab == 'share',
                  fade: actived_tab != 'share',
                }"
                role="tabpanel"
              >
                <share-tab-component :id="id" />
                <!--end card-->
              </div>
              <!--end tab-pane-->
              <div
                class="tab-pane"
                :class="{
                  active: actived_tab == 'transaction',
                  fade: actived_tab != 'transaction',
                }"
                role="tabpanel"
              >
                <transaction-tab-component :id="id" />
              </div>
            </div>
            <!--end tab-content-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import { endpoint, host } from "../../host";
import errorService from "../../services/error-service";
import httpService from "../../services/http-service";
import ShareTabComponent from "./components/ShareTabComponent.vue";
import BiodataTabComponent from "./components/BiodataTabComponent.vue";
import TransactionTabComponent from "./components/TransactionTabComponent.vue";
import InvestorProfileShimmer from "./components/InvestorProfileShimmer.vue";
export default {
  components: {
    ShareTabComponent,
    BiodataTabComponent,
    TransactionTabComponent,
    InvestorProfileShimmer,
  },
  data() {
    return {
      id: null,
      investor: null,
      actived_tab: "biodata",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getInvestor(this.id);
  },
  methods: {
    async getInvestor(id) {
      try {
        let res = await httpService.get(endpoint.investor + `/show/${id}`, {
          headers: httpService.authHeader(),
        });
        this.investor = res.data.data;
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    changeActivedTab(tabName) {
      this.actived_tab = tabName;
    },
  },
};
</script>

<style>
</style>